// extracted by mini-css-extract-plugin
export var activeMenuItem = "header-module--active-menu-item--59e07";
export var bar = "header-module--bar--0b671";
export var fixedMenu = "header-module--fixed-menu--cb93b";
export var hamburgerButton = "header-module--hamburger-button--cb40b";
export var hamburgerIcon = "header-module--hamburger-icon--f3f6c";
export var headerContainer = "header-module--header-container--06a93";
export var headerMenu = "header-module--header-menu--b1864";
export var headerTopBanner = "header-module--header-top-banner--d70f6";
export var headerTopBannerWrapper = "header-module--header-top-banner-wrapper--47c24";
export var headerWrapper = "header-module--header-wrapper--0cbde";
export var logo = "header-module--logo--45dab";
export var logoWrapper = "header-module--logo-wrapper--0564e";
export var menuItems = "header-module--menu-items--e8227";
export var mobileLogo = "header-module--mobile-logo--2b1dc";
export var mobileMenuContainer = "header-module--mobile-menu-container--593c0";
export var special = "header-module--special--a9eaa";