// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--ff450";
export var footerBottomBanner = "footer-module--footer-bottom-banner--d7894";
export var footerColumn = "footer-module--footer-column--d9650";
export var footerContainer = "footer-module--footer-container--68ffd";
export var footerLogo = "footer-module--footer-logo--47043";
export var footerLogoSocial = "footer-module--footer-logo-social--bd72f";
export var footerSocialIcons = "footer-module--footer-social-icons--0153d";
export var footerSpecialRow = "footer-module--footer-special-row--d03d5";
export var footerUpperBanner = "footer-module--footer-upper-banner--f8895";
export var footerWrapper = "footer-module--footer-wrapper--73da0";